.body {
    background-color: #000 !important;
}

.Search_Handler {
    height: 60px;
    width: 80%;
    display: flex;
    margin: 0 auto;
}

.Search_Handler input {
    background-color: #fff;
    width: 80%;
    height: 40px;
    border: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    text-align: center;
    font-size: 14px;
}

*:focus {
    outline: none;
}

.Search_Handler button {
    background-color: rgb(216, 13, 13);
    border: 0;
    width: 20%;
    height: 42px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    cursor: pointer;
    font-weight: 600;
    color: #fff;
    font-size: 13px;
}

.mobile-nav {
    background-color: #222327;
    color: #FFF !important;
    height: 55px;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

}

.mobile-nav-item {
    margin-left: 0px;
    font-family: sans-serif Arial, Helvetica, sans-serif;
   
    line-height: 21.7px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    cursor: pointer;
    transition: all .5s ease-out;
    text-decoration: none;
}
.mobile-nav-item a{
    text-decoration: none;
    color: #CCC ;
}

.mobile-nav-item span {
    font-weight: bold;
    margin-top: 2px;
    font-weight: bold;
}

.mobile-nav-item svg {
    margin-left: 1px;
    margin-top: 5px;
}

.mobile-nav-item:hover  {
    color: #eee;
    margin-top: -4px;
}