.InfoAdmin {}

.info_Admin {}

.info_Admin_box {
    background-color: #222b45 !important;
    border-radius: 12px;
    color: #FFF !important;
    padding: 10px;
    margin-top: 18px;
    height: max-content;
}
@media only screen and (max-width : 600px) {
    .info_Admin{
        /* display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width : 100%;
        background-color: firebrick; */
        
    }
}

.info_Admin_box svg {
    color: #00d68f;
    font-size: 38px !important;
}

.info_Admin_box small {
    color: #FFF !important;
    display: block;
    margin-top: 2px;
}