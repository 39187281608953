/* تغییر رنگ نمایشگر (track) به مشکی */
.BoxOffice::-webkit-scrollbar-track {
    background-color: #383535;}

/* تغییر رنگ کادر اسکرول به مشکی */
.BoxOffice::-webkit-scrollbar {
    width: 15px; /* اندازه کادر اسکرول */
    background-color: #cf0303;}
/* تغییر رنگ نوار اسکرول به مشکی */
.BoxOffice::-webkit-scrollbar-thumb {
    background-color: #0a0000;
}

/* سایر استایل‌ها */
.BoxOffice {
    background: #7b4397;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #dc2430, #7b4397);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #dc2430, #7b4397);
    height: 300px;
    background-repeat: no-repeat;
    background-position: 90% 40%;
    color: #FFF;
    border-radius: 10px;
    padding-bottom: 25px;
    overflow-y: scroll;
    margin-top: 18px;
}
.Item-Box-Office {
    margin-top: 18px;
    height: 125px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-flow: row;
    padding: 10px;
    /* border: 1px solid #ccc; */
    border-radius: 10px;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.Item-Box-Office-img {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 50%;
    height: 125px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: content-box;



}

.Item-Box-Office-text {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    padding-top: 17px;
    width: 50%;
    background-color: #020202;
    height: 125px;


}