.Info_Movie{
    background-color:rgb(31, 40, 50);
    width: 100%;
    height: max-content;
    padding-bottom: 30px;

}
.Info_Movie .Info_Movie_Section ul{
    list-style: none;
    margin-top: 25px;

}
.Info_Movie .Info_Movie_Section ul li{
    color: #FFF;
    font-size: 18px;
    padding: 10px;
    border-bottom: 0.01px solid #444;
    
}
.gallery_Pic{
    margin-left: 0px;
}
.gallery_Pic img{
    width: 100%;
    padding-top: 10px;
    border-radius: 20px !important;
   
}

@media only screen and (min-width: 600px) {
    .gallery_Pic img {
      width: 100%;
      height: 250px;
      border-radius: 8px;
      margin: 5px 5px 0px 0px;
      padding-top: 15px;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }