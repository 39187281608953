.Footer {
    height: max-content;
    padding-bottom: 30px;
    background-color: #424242 !important;
    color: #FFF;
    border-radius: 5px;
    position: relative;
    left: 0px;
    right: 0px;
}






.footer-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-item {
    width: inherit;
    margin-right: 44px;
    cursor: pointer;
}
