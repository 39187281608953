.NavBar_Admin {
    background-color: #222b45 !important;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
    z-index: 5;
}

.BackActive {
    color: yellow;

}

.navbar_dis {
    display: none;
}

@media only screen and (max-width : 600px) {
    .navbar_dis {
        display: block;
    }
}