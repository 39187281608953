.Latest_Trailers {

    width: 100%;
    height: max-content;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

}

.Latest_Trailers_Box {
    width: 100%;
    height: 300px;
    background-position: 100% center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-origin: border-box !important;
    border-radius: 12px;
    padding: 20px;
    margin-top: 10px;
    cursor: pointer;
    transition: 1s all !important;
    padding-bottom: 60px;

}
.Latest_Trailers_Box .trailer-play {
    top: 48%;
    align-items: center;
    justify-content: center;
    display: flex;
    color: rgb(244, 4, 4);
    font-size: 43px;
    text-align: center;
    margin: auto;
    transition: all 2s;

}
.pagin_img{

}
.pagin_img_item{

}
.pagin_img_item img{
    width: 140px !important;
    height: 60px !important;
    
}
.trailer-play:hover{
    
    font-size: 48px;
    color: rgb(244, 14, 74);
}
.Latest_Trailers_Box h4 {
    color: #F0F0F0;
    padding-right: 24px;
    padding-bottom: 7px;
    font-weight: 600;
}

.Latest_Trailers_Box img {
    width: 90%;
    height: 140px;
    border-radius: 9px;
}


.Last_Trailer_Item {
    position: relative;
    /* margin-top: 30px; */
    height: 100px !important;
    margin-top: 45px;
    
}

.play-circle{
    position: absolute;
    font-size: 45px;
    color: red;
    top: 36%;
    right: 0;
    left: 0;
    margin: 0px auto !important;
    text-align: center;
    display: flex !important;
    justify-content: center!important ;
    align-items: center !important;
    transition: 1s all;

}
.play-circle:hover{
    color:black
}