.Movie_Top {}

.Movie_Top img {
    margin-top: 15px;
    width: 100%;
    height: 420px;
    border-radius: 10px;
    padding-right: 10px;
    cursor: pointer;
    object-fit: cover;
    transition: 2s all;
    


}

.Movie_Top img:hover {
    filter: grayscale(100%);

}

.Movie_Top .Coming_Soon {
    position: relative;
}

.Coming_Soon .Coming_Text {
    position: absolute;
    top: 16px;
    left: 0;
    height: 48px;
    width: 48px;
    border-radius: 24%;
    background-color: #e40;
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Series_New {}

.Series_New img {
    width: 100%;
    height: 95px;
    object-fit: cover;
    object-position: 79% 14%;
    border-radius: 8px;
    margin-top: 12px;
    filter: blur(50%);
    transition: 1s all;

    z-index: 10;
}

.Series_New_Item {
    position: relative;
    transition: 1s all;
}

.info_Series_New {
    position: absolute;
    top: 16px;
    right: 0;
    z-index: 1;
    background: #3333;
    display: none;
    width: 240px;
    cursor: pointer;
}

.Series_New_Item:hover .info_Series_New {
    display: inline-block;

}

.info_Series_New span {
    position: relative;
    display: block;
    color: #fefe;
    font-weight: bold;
    padding: 10px;

}

/* Blur */
.Series_New img:hover {

    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
    filter: grayscale(50%);
}