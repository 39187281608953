.IsSelectImg {
    background: rgb(46 114 221 / 74%);
    padding: 7px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    cursor: pointer;
    color: #FFF !important;
}
.boox-insert{
    color: #FFF !important;
}
.Bx_Dl{
    
   
}
.list_Box{
    background-color: #FFF;
    height: max-content;
}