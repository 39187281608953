/* استایل‌دهی به فونت */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: sans-serif;
    font-weight: bold;
}

/* استایل‌دهی به سایه متن */
h1,
h2,
h3,
h4,
h5,
h6 {
    text-shadow: 0 1px 2px black;
}

/* استایل‌دهی به حاشیه و فاصله */
.header {
    margin: 0 auto;
    padding: 10px;
}

.nav {
    margin: 0 auto;
    padding: 10px 0;
}

/* استایل‌دهی به تصاویر و آیکون‌ها */
.logo {
    width: 100px;
    height: 100px;
}

.search-icon {
    width: 20px;
    height: 20px;
}

/* استایل‌دهی به هدر */
.header {
    background-color: #ffffff;
    border-radius: 5px;
}

.Header_Top {
    color: #222;
    width: 100%;
}

/* استایل‌دهی به نوبار */
nav {
    background-color: #002233;
    border-radius: 5px;
    margin-top: 0px;

}
.navbar-spical {
    height: 82px !important;
    box-shadow: 5px 5px red !important;

}

.navbar-nav a {
    color: #f5f5f5 !important;
    text-decoration: none;
    font-weight: 600;
    font-size: 16.5px;
    margin-top: 5px;
    padding-left: 4px;
    transition: 1s all !important;
}
.navbar-nav a:hover i {
    font-size: 22px;
    color: blueviolet !important;

}
.navbar-nav a i {
    color:  #f5f5f5 !important;
    font-size: 20px;
    padding-left: 5px;
    transition: 1s all !important;
}

.navbar-toggler {
    color: #ffffff;
    background: #CCC;
}

.nav-link {
    margin-top: -5px !important;
    font-size: 22px;
    font-weight: 600;
    margin-left: 25px;

}

svg {
    margin-left: 5px;
    margin-top: -3px;
}

.Login_Btn,
.Register_Btn {
    font-family: 'MyWebFont';
    font-weight: 600;
    color: #ffffff;
    padding: 10px 20px;
    border: 2px solid #EEEEEE;
    border-radius: 25px;
    margin-right: 20px;
    transition: all 0.3s ease-in-out;
    border: none !important;
    background-color: #CF0A0A !important;
}

.Login_Btn:hover,
.Register_Btn:hover {
    background-color: #EEEEEE;
    color: #222;
}

.Search {
    z-index: 10;
    position: relative;
}

.Search input {
    border: 0px;
    outline: 0px;
    margin-top: 0px;
    border-radius: 15px;
    padding-right: 15px;
    width: 333px;
    margin-right: 85px;
    background-color: #0f0f0f !important;
    color: #f8f6f6 !important;
}

.Result_Search {
    position: absolute;
    width: 100%;
    background-color: #222;
    z-index: 25;
    top: 100%;
    color: #ffffff;
    border-radius: 15px;
    padding: 20px;
    display: none;
}

input::placeholder {
    color: #d1d1d1 !important;


}


a {
    color: #ffffff;
    text-decoration: none;
}

@media screen and (max-width: 991px) {
    .nav-link {
        font-size: 18px;
        margin-left: 15px;
    }

    svg {
        margin-left: 0px;
        margin-right: 5px;
    }
}