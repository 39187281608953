.BoxDownload{
    background-color: #2d2d2c;
    width: 100%;
    height: max-content;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 15px;
    color: #DDD;
    font-size: 20px;
    margin-top: 25px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.accordion .Dubbed button{
    background-color: #000;
    border: none;
    color: #FFF;
    font-weight: 500;
}
.accordion .Subbed button{
     background-color: #414141;
     border: none;
     color: #FFF;
     font-weight: 500;
}
.accordion{
    color: #FFF;
}
/* .accordion-body{

} */
.accordion-body .item-download{
    position: relative;

}
.accordion-body .item-download img{
    width: 100px;
    position: relative;
}
.accordion-body .item-download span{
    position: absolute;
    top: 44px;
    right: 50px;
    font-weight: 700;
    font-size: 20px;
}